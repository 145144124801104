import React from 'react'
import PropTypes from 'prop-types'

const ConsultantPageTemplate = ({ consultants }) => {

  return (
    <section className="section section--gradient">
      <div className="content-spacer"></div>
      <section className="section">

      <div className="content container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
              <h1 className="is-size-2">District Consultants and Vendors</h1>
              <hr/>
              <div className="columns is-multiline">
                {consultants.map(consultant => {
                  function Website({consultant}) {
                    if(consultant.website !== null)
                    {
                      if(consultant.website.startsWith('mailto'))
                      {
                        return (< ><br/><a href={consultant.website}>Email</a></ >)
                      }
                      else
                      {
                        return (< ><br/><a href={consultant.website}>Website</a></ >)
                      }
                    }
                    else { return (< ></ >)}
                  }
                  return (
                    <div key={consultant.company} className="column is-4">
                      <div className="card">
                        <div className="card-header">
                          <div className="card-header-title">
                            {consultant.role}
                          </div>
                        </div>
                        <div className="card-content">
                          <p class="has-text-grey"  style={{lineHeight: '1rem'}}><small><em>{consultant.description}</em></small></p>
                          <p><small>{consultant.company}<br/>
                          {consultant.address1}<br/>
                          {consultant.address2}
                          <Website consultant={consultant} />
                          </small>
                        </p>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
          </div>
        </div>
      </div>
      </section>
    </section>
  )
}

ConsultantPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default ConsultantPageTemplate
